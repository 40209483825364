@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-grey: #A8A8A8;
  --color-gray-dark: #7D7D7D;
  --color-green: #5CBB7A;
  --color-red: #FF4D4F;
  --bg-green: #EBF5EF;
  --bg-green-hover: #D9FBE7;

  --height-header: 62px;
  --height-info: 0px;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

*,*:after,*:before {
  box-sizing: border-box;
  vertical-align: middle;
}
/* Form style */
input[type="radio"] {
  appearance: none; /* Убираем стандартный стиль */
  width: 16px; /* Указываем ширину кружка */
  height: 16px; /* Указываем высоту кружка */
  border: 1px solid var(--color-green); /* Задаем зеленую рамку */
  border-radius: 50%; /* Делаем его круглым */
  outline: none; /* Убираем обводку при фокусе */
  cursor: pointer; /* Указатель при наведении */
  position: relative; /* Для удобного размещения псевдоэлемента */
}


input[type="radio"]:checked::after {
  content: ''; /* Псевдоэлемент */
  position: absolute; /* Позиционирование */
  top: 50%; /* Центрирование по вертикали */
  left: 50%; /* Центрирование по горизонтали */
  width: 8px; /* Ширина маленького кружка */
  height: 8px; /* Высота маленького кружка */
  background-color: var(--color-green); /* Цвет заполненного круга */
  border-radius: 50%; /* Круглая форма */
  transform: translate(-50%, -50%); /* Центрирование */
}
input[type="checkbox"] {
  appearance: none; /* Убираем стандартный стиль */
  width: 16px; /* Ширина */
  height: 16px; /* Высота */
  border: 1px solid #D9D9D9; /* Зеленая рамка */
  background-color: white; /* Фон по умолчанию */
  cursor: pointer; /* Указатель при наведении */
  outline: none; /* Убираем обводку при фокусе */
  border-radius: 2px;
  position: relative;
}

input[type="checkbox"]:checked {
  background-color: var(--color-green); /* Фон при выборе */
  border: 2px solid var(--color-green); /* Изменение цвета рамки при выборе */
}

input[type="checkbox"]:checked::after {
  content: ''; /* Псевдоэлемент */
  position: absolute; /* Абсолютное позиционирование */
  left: 3px; /* Положение по горизонтали */
  top: 0px; /* Положение по вертикали */
  width: 6px; /* Ширина галочки */
  height: 9px; /* Высота галочки */
  border: solid white; /* Цвет галочки */
  border-width: 0 2px 2px 0; /* Формирование галочки */
  transform: rotate(45deg); /* Поворот для галочки */
}

/* End form style*/
.box-container {
  min-width: 1400px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-gray-pa {
  background-color: rgb(247, 247, 247);;
}
.bg-green-pa {
  background-color: var(--color-green);
}
.bg-lite-pa {
  background: var(--bg-green);
}
.bg-lite-pa:hover {
  background-color: var(--bg-green-hover);
}
.bg-lemon-pa {
  background-color: #FFF0BD;
}
.color-red-pa {
  color: var(--color-red);
}
.color-green-pa {
  color: #5CBB7A;
}
.color-gray-pa {
  color: var(--color-grey);
}
.color-gray-dark-pa {
  color: var(--color-gray-dark);
}
.green-hover:hover {
  color: #5CBB7A;
}
.border-gray-pa {
  border-color: #D9D9D9;
}
.border-green-pa {
  border-color: var(--color-green);
}
.border-red-pa {
  border-color: var(--color-red);
}
.input-search-iin {
  width: 475px;
}
.arrow-find {
  position: absolute;
  left: 320px;
  top: 20px
}

.modal-pa {
  display: none;
  min-width: 800px;
}

.patient-item {
  background: #EBF5EF;
}
.menu-item {
  color: var(--color-grey);
}
.menu-item_active {
  background: var(--bg-green);
  color: var(--color-green);
}
.menu-item_active svg path {
  fill: var(--color-green);
}
.menu-button {
  background: var(--color-green);
  color: #fff;
}
.menu-visit_active {
  background: #fff;
}
.drop-down__svg {
  transition: transform 0.3s ease-out;
}
.drop-down__svg_close {
  transform: rotate(180deg);
}
.list-sidebar-zebra > *:nth-child(2n){
  background: #F7F7F7;
}
.field-set-visit__title {

}
.field-set-visit__title:before {
  content: " ";
  top: 50%;
  z-index: 0;
  left: 0;
  width: 100%;
  position: absolute;
  border-top: 1px solid #e5e7eb;
}

.analys-table > *:nth-child(2n-1) {
  background-color: #FFF;

}
.analys-table > *:nth-child(2n) {
  background-color: #F7F7F7;
}


.input-range__container {
  position: relative;
}

.input-range {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 14px + var(--ratio) * (100% - 14px));
  border: 0px;
}

.input-range:hover {
  opacity: 1;
}



.input-range::-webkit-slider-thumb {
  width: 14px;
  height: 14px;
  border-radius: 20px;
  background: #fff;
  border: 2px solid var(--color-green);
  box-shadow: none;
  margin-top: calc(2px * 0.5 - max(12px * 0.5,2px));
  -webkit-appearance: none
}

.input-range::-webkit-slider-runnable-track {
  height: 4px;
  border-radius: 0;
  background: #DEF1E4;
  border: none;
  box-shadow: none
}

.input-range::-webkit-slider-runnable-track {
  background: linear-gradient(var(--color-green), var(--color-green)) 0/var(--sx) 100% no-repeat,#DEF1E4
}

.input-range::-moz-range-thumb {
  width: max(calc(14px - 2px - 2px),0px);
  height: max(calc(14px - 2px - 2px),0px);
  border-radius: 14px;
  background: #fff;
  border: 2px solid var(--color-green);
  box-shadow: none
}

.input-range::-moz-range-track {
  height: 4px;
  border-radius: 0;
  background: #fff;
  border: none;
  box-shadow: none
}

.input-range::-moz-range-track {
  background: linear-gradient(var(--color-green),var(--color-green)) 0/var(--sx) 100% no-repeat,#fff
}

.input-range::-ms-fill-upper {
  background: transparent;
  border-color: transparent
}

.input-range::-ms-fill-lower {
  background: transparent;
  border-color: transparent
}

.input-range::-ms-thumb {
  width: 14px;
  height: 14px;
  border-radius: 14px;
  background: #fff;
  border: 2px solid var(--color-green);
  box-shadow: none;
  margin-top: 0;
  box-sizing: border-box
}

.input-range::-ms-track {
  height: 2px;
  border-radius: 0;
  background: #fff;
  border: none;
  box-shadow: none;
  box-sizing: border-box
}

.input-range::-ms-fill-lower {
  height: 2px;
  border-radius: 0 0 0 0;
  background: #204185;
  border: none;
  border-right-width: 0
}


.input-range__label {
  position: absolute;
  top: -25px; /* Позиция над бегунком */
  font-size: 14px;
  padding: 2px;
  border-radius: 3px;
  transform: translateX(50%);
}

.alarm-bg-pa {
  background-image: linear-gradient(135deg, black 25%, transparent 25%, transparent 50%, black 50%, black 75%, transparent 75%, transparent);
  background-size: 40px 40px; /* Размер полос */
  width: 100%; /* Ширина на весь контейнер */
}
.alarm-text-pa {
  color: black;
}
.tooltip-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  position: absolute;
  left: 20px;
  bottom: -5px;
  transform: translateX(-50%);
}
.overflow-wrap-pa {
  overflow-wrap: break-word;
}
.input-file-pa::-webkit-file-upload-button {
  display: none;
}
/* Добавьте в ваш CSS файл */
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 100; /* Обеспечивает, что хедер будет поверх других элементов */
}

.sticky-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: calc(var(--height-header) + var(--height-info) ); /* прилипать к верхней части */
  height: calc(100vh - var(--height-header) - var(--height-info)  ); /* сделать сайдбар высотой экрана */
  overflow-y: auto; /* добавьте прокрутку, если содержимое больше */
  min-height: calc(100vh - var(--height-header) - var(--height-info)  );
}